import React from "react"

import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import SignUpEmail from "../../../components/signUpEmail";
import img1 from "../../../images/marketing-services/seo/seo-img-1.svg";
import img2 from "../../../images/marketing-services/seo/seo-img-2.svg";
import img3 from "../../../images/marketing-services/seo/seo-img-3.png";
import img4 from "../../../images/marketing-services/seo/seo-img-4.svg";
import AccordionComponent from "../../../components/AccordionComponent"

const seoPage = () => (
  <Layout css="seoPageCss">
    <SEO title="Boston SEO Agency"
         description={"Looking for the best internet marketing services in Boston? Let our team of experts help you crush your digital marketing goals and crush the competition."}/>
    <div className={"container hero section-padding-wrapper"}>
      <div className={"row align-items-center m-flex-direction-col-rev"}>
        <div className={"col-md-6"}>
          <h1><span className={"red"}>SEARCH ENGINE OPTIMIZATION </span>IN BOSTON</h1>
          <p className={"subtitle"}>Our Boston-based SEO experts can help you gain visibility in local and national
            search results. Stop struggling with SEO and get the views you deserve!</p>
          <a href={"/contact#contact-today"} className={"button-red"}>REQUEST A CALL</a>
        </div>
        <div className={"col-md-6"}>
          <img alt={""} src={img1} className={"w-100 m-pic-align"}/>
        </div>
      </div>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"row justify-content-center"}>
        <div className={"col-md-5"}>
          <img alt={""} src={img2} className={"w-100 m-pic-align"}/>
        </div>
        <div className={"col-md-7 p-top-20 p-bottom-20"}>
          <div className={"text-align-center"}>
            <h2>WHAT IS SEARCH ENGINE OPTIMIZATION</h2>
          </div>
          <p className={"align-items-center"}>Search Engine Optimization or SEO is one of the technical means
            of building a website or improving its standing on a web browser. SEO's primary aim is to improve your
            site's rank among the results in a search engine (SERPs). All the techniques that lead to a better rank on
            search engines are collectively called Search Engine Optimization.</p>
          <p className={"align-items-center"}>At Avantcy Marketing, we offer a Boston SEO expert
            understanding of how various search engines read your webpage. Among these search engines, we optimize site
            content for platforms like:</p>
          <ul className={"text-align-left"}>
            <li><strong>Google</strong></li>
            <li><strong>Yahoo</strong></li>
            <li><strong>Bing</strong></li>
            <li><strong>Ask.com and more</strong></li>
          </ul>
        </div>
      </div>
      <AccordionComponent label="Improved Credibility">
        <p>The typical consumer behavior is equating higher-ranking content with more credibility. It occurs primarily
          when the top pages feature similar meta descriptions or content. Being among the top SERPs portrays the value,
          detail, and credibility of your content better.</p>
      </AccordionComponent>
      <AccordionComponent label="An Enhanced Brand Visibility">
        <p>Most of the sites that are regarded as the top in their field by customers are among the first five SERPs.
          The reason is that their brand has better visibility from being at the top. Maximizing your page for search
          engines allows you to reach the top of your niche with better visibility.</p>
      </AccordionComponent>
      <AccordionComponent label="Better Customer Gain and Retention Rates">
        <p>When potential customers search for you, they may find your competitors first. Joining us to ensure proper
          Search Engine Optimization will help you gain more customers. Additionally, the techniques of SEO also lead to
          better customer retention rates.</p>
        <p>If you want to enjoy these benefits and more, contact us today to get started.</p>
      </AccordionComponent>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"text-align-center"}>
        <h2>BENEFITS OF <span>SEARCH ENGINE OPTIMIZATION</span></h2>
      </div>
      <h3 className={"subtitle m-bottom-75"}>Our Boston SEO services build new websites to fit certain
        technicalities or adjust old websites to improve their standing. Proper Search Engine Optimization targets
        your local audience and also brings traffic from other parts of the world. Some of our SEO targets include
        the following.</h3>
      <AccordionComponent label="Keyword Optimization">
        <p>Keywords are one of the significant drivers of organic site traffic. The right keyword combinations will
          yield the results you need. Combined with other local SEO services, Avantcy Marketing increases your search
          engine rank. Our keywords optimization strategies can take three faces. We can:</p>
        <ul className={"text-align-left"}>
          <li><strong>Use an in-depth keyword search to find those related to your niche</strong></li>
          <li><strong>Target keywords from your well-performing competitor sites</strong></li>
          <li><strong>Use the results of conversions from pay per click adverts (check out our PPC services)</strong>
          </li>
        </ul>
      </AccordionComponent>
      <AccordionComponent label="Link Building">
        <p>The ability to convince high ranking and quality, related sites to link to your site is another aspect of
          SEO. Despite its effectiveness, it is quite time-consuming. So, it is best to contract the process to a team
          like ours, which is dedicated to ensuring you receive a high rank. We are allied with relevant, high-quality
          websites that will help eliminate the potential for spam links.</p>
      </AccordionComponent>
      <AccordionComponent label="Technical SEO">
        <p>Technical SEO focus on improving your website, especially the page speed. Improving speed makes it easier for
          search engines to skim your page's content to check its relatedness to the keywords in searches. We focus on
          achieving this feat to improve your site's ranking.</p>
      </AccordionComponent>
      <AccordionComponent label="Optimizing Site Content For Maximal Effectiveness">
        <p>To implement the keywords effectively, we will need to tweak your website content. We will have to ensure
          that existing content features the selected keywords. In some cases, we may have to recreate new content for
          the best results. (check out our content writing services).</p>
        <p>Lastly, we monitor your site's progress for positive results and make necessary changes if the need arises.
          In addition to these essential SEO services, we also target Organic and local SEO for maximal efficiency.</p>
      </AccordionComponent>
    </div>
    <div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <div className={"d-flex justify-content-center flex-column-mobile m-flex-direction-col-rev"}>
          <div className={"flex-equal-width m-3 text-align-center"}>
            <h2>ORGANIC SEO</h2>
            <p className={"text-align-left"}>Organic SEO includes a bunch of unified strategies that promote search
              engine visibility. It ensures that:</p>
            <ul className={"checkmark list-style-none text-align-left"}>
              <li><span className={"black-text"}>Your website loads fast</span></li>
              <li><span className={"black-text"}>Your site is user-friendly</span></li>
              <li><span className={"black-text"}>Your content is readable by search engines</span></li>
            </ul>
            <p className={"text-align-left"}>In our efforts to meet all these needs, Avantcy Marketing's organic SEO
              plan is multifaceted. You can use organic SEO to improve the scale of your brand's impact. And Avantcy
              Marketing offers you a space to grow your business effectively by driving traffic to your website. Many
              people are inclined to take advantage of our Boston SEO services because of the tight competition on
              SERPs.</p>
            <p className={"text-align-left"}>Our organic Search Engine Optimization services include on-page and
              off-page optimization. On-page optimization happens on your site and is easier to control. On the other
              hand, off-page optimization is challenging since it happens off your website and requires tons of effort
              and time. Avantcy Marketing is willing to put in the required effort to:</p>
            <ul className={"checkmark list-style-none text-align-left"}>
              <li><span className={"black-text"}>Carry out the tasking process of outreach.</span></li>
              <li><span
                className={"black-text"}>Build a relationship with bloggers and influencers in your field.</span></li>
              <li><span className={"black-text"}>Improve your backlink profile through these relationships</span></li>
            </ul>
            <p className={"text-align-left"}>Even if on-page optimization isn't too demanding, we are willing to put in
              the dedication and time it needs. We can help your team to improve your site's content for search engines
              and users.</p>
            <p className={"text-align-left"}>Some Organic Search Engine Optimization tasks you can expect from our SEO
              services include:</p>
            <ul className={"checkmark list-style-none text-align-left"}>
              <li><span className={"black-text"}>Title tag and Meta description optimization</span></li>
              <li><span className={"black-text"}>Compressing images for better site speed </span></li>
              <li><span className={"black-text"}>Including descriptive alt texts for images</span></li>
              <li><span className={"black-text"}>Targeting new keywords</span></li>
            </ul>
            <p className={"text-align-left"}>Organic SEO is a continuous, ongoing marketing strategy that demands a
              long-term commitment. And you can trust Avantcy Marketing with it. After all the detailed procedures for
              organic SEO, we test it out and improve results.</p>
          </div>
          <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
            <img alt={""} src={img3} id={"choosing-pic"}/>
          </div>
        </div>
      </div>
    </div>
    <div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <div className={"d-flex justify-content-center flex-column-mobile"}>
          <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
            <img alt={""} src={img4} id={"choosing-pic"}/>
          </div>
          <div className={"flex-equal-width m-3 text-align-center"}>
            <h2>LOCAL SEO</h2>
            <p className={"text-align-left"}>Avantcy Marketing local SEO services help you organize your brand for local
              discoverability. For example, if you are a visitor around Boston currently viewing our page, it is the
              results of our local SEO effort. Local search engine optimization helps local businesses increase
              visibility and allows them to reach more customers and boost revenue.</p>
            <p className={"text-align-left"}>Local SEO works like traditional SEO to improve your business listing on
              Google "near me" searches. That
              way, you can appear at the top and have more people contact you quickly.</p>
            <p className={"text-align-left"}>Some features of local SEO we offer include:</p>
            <ul className={"checkmark list-style-none text-align-left"}>
              <li><span className={"black-text"}>On-page local SEO</span></li>
              <li><span className={"black-text"}>Local keyword search</span></li>
              <li><span className={"black-text"}>Google my business optimization</span></li>
              <li><span className={"black-text"}>Citation setup and more</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    {/*<div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <SignUpEmail/>
      </div>
    </div>*/}
  </Layout>
);

export default seoPage;
